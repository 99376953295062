.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.call-video {
  height: 85vh;
}

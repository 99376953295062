.header.sticky-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    animation: slideDown 0.35s ease-out;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}
.react-bs-table-no-data div {
  text-align: center;
}

.react-datepicker-wrapper {
  display: block !important;
}
a {
  text-decoration: none !important;
}



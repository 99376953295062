.from-message {
  text-align: right;
  label {
    padding: 5px 10px;
    background-color: #ebebeb;
    border-radius: 7px;
  }
}
.to-message {
  text-align: left;
  label {
    padding: 5px 10px;
    background-color: #ebebeb;
    border-radius: 7px;
  }
}

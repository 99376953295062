.wrap-login100 {
  border-radius: 10px;
  background: linear-gradient(263deg in, white #75D5CA);
  justify-content: center;
}

.main-wrapper .bg {
  padding: 68px;
}

.form-group label {
  font-size: 18px;
}
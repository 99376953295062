.doctor-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
//   gap: 20px;
}

.doctor-card {
  background: #fff;
  border: 1px solid #e4e4e4;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.doctor-card:hover {
  transform: translateY(-5px);
}

// .doctor-img img {
//   max-width: 100%;
//   border-radius: 50%;
// }

.doctor-details {
  margin: 10px 0;
}

.doctor-name {
  font-size: 1.2em;
  margin-top: 10px;
}


.service-charge {
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 10px;
}

.appointment-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}



.doctor-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Highlight on hover */
}

.label {
  background-color: #1DBFC1; /* Blue background for labels */
  color: #fff; /* White text on blue background */
  padding: 5px 10px;
  margin: 5px;
  margin-left: 0px;
//   display: inline-block;
    font-weight: 700;
  text-align: center;
}

.service-charge span {
  background-color: #1DBFC1; /* Green background for the service charge */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

// .appointment-buttons button {
//   background-color: #007bff; /* Blue background for buttons */
//   color: #fff;
//   border: none;
//   padding: 5px 10px;
//   border-radius: 5px;
//   cursor: pointer;
//   margin-top: 10px;
// }

@media (min-width: 768px) {
  /* Apply desktop styles when the screen width is at least 768px */
  .doctor-card {
    padding: 30px;
    margin: 20px;
  }

  .doctor-card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3); /* Larger highlight on hover */
  }

  .doctor-img img {
    max-width: 150px; /* Adjust the image size for larger screens */
  }
}

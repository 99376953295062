/* PaymentStatus.css */

.page-wrapper {
  background-color: #f5f5f5;
}

// .payment-status {
//   margin-top: 20px;
//   text-align: left;
// }

// .status {
//   font-size: 24px;
//   padding: 10px;
//   border-radius: 5px;
//   font-weight: bold;
//   // color: #ffffff;
// }

// .paid {
//   background-color: #4caf50; /* Green for success */
// }

// .failed {
//   background-color: #f44336; /* Red for failure */
// }

.amount {
  font-size: 20px;
  margin-top: 10px;
}

/* Add additional styles as needed */

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    backdrop-filter: blur(4px);
    /* Apply a blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    height: 200px;
}

.buttons {
    margin-top: 20px;
}

.proceed-btn {
    background-color: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
}

.cancel-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}
.active-row {
  background-color: lightgreen !important;
}

.inactive-row {
  background-color: lightcoral !important;
}

.custom-dropdown-form-table::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
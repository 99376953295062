/* faq.css */

.bg-cover {
    background-color: #222222;
}



.faq-container {
    margin-bottom: 20px;
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
}

.faq-question-text {
    flex: 1;
}

.faq-answer {
    padding: 10px;
    background-color: #e0e0e0;
    border-left: 3px solid #007bff;
}

.faq-icon {
    font-size: 20px;
}

.rotate-180 {
    transform: rotate(180deg);
}
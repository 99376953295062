.tab {
    background-color: #1F55C7;
    color: white;
    padding: 10px 40px;
    border-radius: 10px;
    margin: 0px 10px
}

.active-tabs {
    background-color: #04CE78;
    color: white;
    padding: 10px 40px;
    border-radius: 10px;
    margin: 0px 10px
}